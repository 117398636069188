<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

const props = withDefaults(defineProps<Props>(), {
  label: '',
  disabled: false,
});

export interface Props {
  /** Label appears beside toggle */
  label?: string;
  disabled?: boolean;
}

const model = defineModel<boolean>({ required: true });
</script>

<template>
  <SwitchGroup
    as="div"
    class="flex items-center"
  >
    <Switch
      v-model="model"
      class="group relative h-5 w-10 inline-flex flex-shrink-0 cursor-pointer items-center justify-center rounded-full disabled:bg-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:!pointer-events-none disabled:!cursor-default"
      :disabled="props?.disabled"
      :class="[
        modelValue ? 'bg-primary-500' : 'bg-slate-200',
        modelValue && props.disabled ? 'disabled:!bg-neutral-500' : '',
      ]"
    >
      <span
        aria-hidden="true"
        class="pointer-events-none absolute h-full w-full rounded-md"
      />
      <span
        aria-hidden="true"
        class="pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out group-disabled:bg-neutral-300 group-disabled:!pointer-events-none group-disabled:!cursor-default" :class="[
          modelValue ? 'bg-primary-500' : 'bg-slate-200',
          modelValue && props.disabled ? 'group-disabled:!bg-neutral-500' : '',
        ]"
      />
      <span
        aria-hidden="true"
        class="pointer-events-none absolute left-0 inline-block h-5 w-5 transform border border-slate-200 rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-disabled:border-neutral-200 group-disabled:bg-[linear-gradient(130deg,transparent_0%,transparent_45%,#e5e5e5_46%,#e5e5e5_50%,transparent_51%,transparent_100%)]" :class="[
          modelValue ? 'translate-x-5' : 'translate-x-0',
        ]"
      />
    </Switch>
    <SwitchLabel
      as="span"
      class="ml-3"
    >
      <slot name="left-icon" />
      <span class="text-base text-gray-900 font-medium">{{ props.label }}</span>
    </SwitchLabel>
  </SwitchGroup>
</template>

<style>
.elementThing {
  position: relative;

  &:before {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 10;
  }
}
</style>
