import { z } from 'zod';
import { EventSchema } from './events';
import { metaSchema } from './response';

export const tagsSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string(),
});

const UserSchema = z.object({
  id: z.number(),
  firstname: z.string(),
  lastname: z.string(),
});

const EmailMetaSchema = z.object({
  subject: z.string(),
  preheader: z.string(),
});

export const BaseLanguageSchema = z.object({
  code: z.string(),
  id: z.number(),
  title: z.string().optional(),
  name: z.string().optional().default(''),
  shortcut: z.string().optional(),
  symbol: z.string().optional(),
  organization_language_id: z.number().nullable().optional(),
  position: z.number().optional(),
});

export const LocalLanguageSchema = BaseLanguageSchema.extend({
  _shortcut_error: z.string().optional().nullable(),
  _previous_shortcut: z.string().optional(),
});

export const templateSchema = z.object({
  id: z.number(),
  name: z.string(),
  feedback: z.boolean(),
  multi_language: z.boolean(),
  dynamic_content_enabled: z.boolean(),
  bee_session_id: z.string().nullable(),
  template_type: z.string(),
  thumbnail_key: z.string().nullable(),
  thumbnail_updated_at: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  thumbnail_url: z.string().url(),
  user: UserSchema,
  last_edited_by: UserSchema,
  meta: EmailMetaSchema,
  tags: z.array(tagsSchema),
  languages: LocalLanguageSchema.array().optional(),
});
export const templateResponseSchema = z.object({
  templates: z.array(templateSchema),
  meta: metaSchema,
});

export const SampleTemplateSchema = z.object({
  display_name: z.string(),
  name: z.string(),
  thumbnail_url: z.string(),
});

export const tagsResponseSchema = z.object({
  tags: z.array(tagsSchema),
});

export const mmcSchema = z.object({
  events: EventSchema.array(),
  id: z.number(),
  sent_at: z.string(),
  sent_by: z.string(),
  sent_from: z.string(),
  sent_to: z.array(z.string()),
  subject: z.string(),
  has_segmented_stats: z.boolean(),
  click_maps_enabled: z.boolean(),
  social_channel_posting_available: z.boolean(),
  dynamic_content_enabled: z.boolean(),
  template_html: z.string(),
  thumbnail_url: z.string(),
});

export type TemplateResponse = z.infer<typeof templateResponseSchema>;
export type Tag = z.infer<typeof tagsSchema>;
export type Template = z.infer<typeof templateSchema>;
export type TagResponse = z.infer<typeof tagsResponseSchema>;
export type MMC = z.infer<typeof mmcSchema>;
export type LocalLanguage = z.infer<typeof LocalLanguageSchema>;
export type SampleTemplate = z.infer<typeof SampleTemplateSchema>;
