import type { OrganizationTeam, User, UserApi } from '@/types/user';
import { cmApi } from '@/api/instance';
import { currentUserSchema } from '@/types/user';
import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';
import { z } from 'zod';

// TODO: with BE team check if error format is proper
const axiosErrorSchema = z.object({
  response: z.object({
    data: z.object({
      error: z.object({
        message: z.string(),
      }),
    }),
    status: z.number(),
  }),
});

// type axiosError = z.infer<typeof axiosErrorSchema>;

export const useUserStore = defineStore('users', {
  state: () => ({
    currentUser: null as User | null,
    currentTeam: null as OrganizationTeam | null,
    availableTeams: [] as OrganizationTeam[],
  }),

  getters: {
    ownerOrAdmin: state => !!(state.currentUser?.role === 'owner' || state.currentUser?.role === 'administrator'),
    smsUser: state => (!state.currentUser?.mail_merge_campaigns_enabled && state.currentUser?.sms_campaigns_enabled),
    mailUser: state => (!state.currentUser?.sms_campaigns_enabled && state.currentUser?.mail_merge_campaigns_enabled),
    dualUser: state => (state.currentUser?.sms_campaigns_enabled && state.currentUser.mail_merge_campaigns_enabled),
    dynamicContentEnabled: state => state.currentUser?.organization.dynamic_content_enabled,
    dynamicContentV2Enabled: state => state.currentUser?.feature_flags.ff_dynamic_content_v2,
    smsTrialUser: state => ((!state.currentUser?.sms_campaigns_enabled && state.currentUser?.mail_merge_campaigns_enabled) && state.currentUser?.sms_campaign_trial_enabled),
    socialChannelsUser: state => (state.currentUser?.organization?.social_channels_enabled),
    multiLanguageUser: state => (state.currentUser?.organization?.email_translations_toggle_visible && state.currentUser?.organization.email_translations_enabled),
    msTeamsUser: state => (state.currentUser?.organization?.social_channels_enabled && state.currentUser.organization?.ms_teams_enabled),
    msSharepointUser: state => (state.currentUser?.organization?.social_channels_enabled && state.currentUser.organization?.ms_sharepoint_enabled),
    isUserSmsOnly: state => (!state.currentUser?.mail_merge_campaigns_enabled && state.currentUser?.sms_campaigns_enabled && !(state.currentUser?.sms_campaigns_enabled && state.currentUser.mail_merge_campaigns_enabled)),
    isUserMailOnly: state => (!state.currentUser?.sms_campaigns_enabled && state.currentUser?.mail_merge_campaigns_enabled && !(state.currentUser.sms_campaigns_enabled && state.currentUser.mail_merge_campaigns_enabled)),
    isUserDual: state => (state.currentUser?.sms_campaigns_enabled && state.currentUser.mail_merge_campaigns_enabled),
    timezoneSendingEnabled: state => state.currentUser?.organization.timezone_sending_enabled,
  },

  actions: {
    async fetchUser() {
      try {
        const { data } = await cmApi<UserApi>('/users/info');
        // setting the user ID before the zod schema is parsed so that we always have Sentry set the User id if
        // the api request for User info succeeds from a backend perspective
        Sentry.setUser({ id: data.user.id });
        const parsedData = currentUserSchema.safeParse(data.user);
        if (!parsedData.success) {
          console.error(parsedData.error.errors);
          console.error('invalid user data');
          const error = new Error('invalid user data');
          error.name = 'InvalidUserData';
          Sentry.captureException(error);
          throw error;
        }
        this.currentUser = parsedData.data;
        this.availableTeams = parsedData.data.organization_teams;

        // TODO: should consider moving canny calls into onMounted hook on app.vue and abstract the JS out
        if (this.currentUser.internal_communication) {
          if (typeof window.Canny === 'function') {
            // check with Nick if adding the window.Canny call is ok to do
            window.Canny('identify', {
              appID: '65947205893793303f300b7c',
              user: {
                email: this.currentUser.email,
                name: `${this.currentUser.firstname} ${this.currentUser.lastname}`,
                id: `${this.currentUser.region}_${this.currentUser.id}`,
              },
            });
          }

          // Init Pendo
          if (window.pendo) {
            window.pendo?.initialize({
              visitor: {
                id: `${this.currentUser?.region}_${this.currentUser?.id}`,
                role: this.currentUser?.role,
                joined_ic_org_at: this.currentUser?.joined_ic_org_at,
                teamId: this.currentUser?.organization_team.id,
              },
              account: {
                id: `${this.currentUser?.region}_${this.currentUser?.organization.id}`,
                name: this.currentUser?.organization.name,
                region: this.currentUser?.region,
                outlook_dashboard_sending_enabled: this.currentUser?.outlook_dashboard_sending_enabled,
                gmail_dashboard_sending_enabled: this.currentUser?.gmail_dashboard_sending_enabled,
                remaining_licenses: this.currentUser?.organization.remaining_licenses,
                sms_campaigns_enabled: this.currentUser?.sms_campaigns_enabled,
                dynamic_content_enabled: this.currentUser?.organization?.dynamic_content_enabled,
                list_management_enabled: this.currentUser?.list_management_enabled,
                ms_sharepoint_enabled: this.currentUser?.organization.ms_sharepoint_enabled,
              },
            });
          }
        }
      }
      catch (error) {
        const parsedError = axiosErrorSchema.safeParse(error);
        if (parsedError.success) {
          console.error(parsedError.data.response.data.error);
        }
        // keeping the original error for now to ensure we continue to get the error message
        // TODO: explicitly check if backend sends errors consistently then remove the else block
        else {
          console.error(error);
          // @ts-expect-error - this is a workaround for a bug in zod where it doesn't recognize the error as a zod error, see todo above
          console.error(error.response);
        }
      }
    },

    updateCurrentTeam(id: number) {
      const newTeam = this.availableTeams.find(team => team.id === id);
      if (newTeam) {
        this.currentTeam = newTeam;
      }
    },
  },
});
