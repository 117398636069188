import { z } from 'zod';

export const metaSchema = z.object({
  page: z.coerce.number(),
  per_page: z.coerce.number(),
  total_entries: z.coerce.number(),
  total_pages: z.coerce.number(),
  sort_by: z.string(),
  order: z.string(),
});
export type MetaType = z.infer<typeof metaSchema>;
