import router from '@/router/index.js';
import * as Sentry from '@sentry/vue';
import { createHead } from '@vueuse/head';
import { provideGlobalGridOptions } from 'ag-grid-community';
import { AllEnterpriseModule, LicenseManager, ModuleRegistry, ServerSideRowModelModule } from 'ag-grid-enterprise';
import FloatingVue from 'floating-vue';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import { createApp } from 'vue';
// @ts-expect-error - should try and find an alternative to this package, manually implement something or a new package
import Vue3Sanitize from 'vue-3-sanitize';

import App from './App.vue';

import '@unocss/reset/tailwind.css';

import 'uno.css';
import 'floating-vue/dist/style.css';
import './initializers/canny';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './assets/fonts.css';
import './assets/index.css';

const agGridLicenseKey = import.meta.env.VITE_AGGRID_KEY;
LicenseManager.setLicenseKey(agGridLicenseKey);
ModuleRegistry.registerModules([ServerSideRowModelModule, AllEnterpriseModule]);
provideGlobalGridOptions({ theme: 'legacy' });

const netlifyRegex = /^https:\/\/deploy-preview-\d+--app-frontend-staging\.netlify\.app$/;

const head = createHead();

const defaultSanitizationOptions = {
  allowedTags: ['span', 'br'],
};

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(FloatingVue)
  .use(head)
  .use(PrimeVue, {
    theme: 'none',
  })
  .use(Vue3Sanitize, defaultSanitizationOptions);

// using node env to enable/disable tracing, this is seperate from VITE_APP_ENV which is used to identify app in traces
if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://f229bd5193d94da398f9e4d55d82b60a@o331479.ingest.sentry.io/6178850',
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
    ],
    tracePropagationTargets: ['appstaging.contactmonkey.com', 'app.contactmonkey.com', netlifyRegex],
    // @ts-expect-error - this is a valid option its weird and Vite like
    release: __APP_VERSION__,
    environment: import.meta.env.VITE_APP_ENV,
    maxBreadcrumbs: 30,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

app.mount('#app');
