<script setup lang="ts">
import CSelect from '@/components/base/CSelect.vue';
import NavBar from '@/components/NavBar.vue';
import SmsNavBar from '@/components/SmsNavBar.vue';
import { useAlertStore } from '@/stores/alertStore';
import { useUserStore } from '@/stores/userStore';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { onClickOutside } from '@vueuse/core';
import { computed, onBeforeMount, onMounted, ref, watchEffect } from 'vue';
import { type RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { cmApi, setupInterceptor } from './api/instance';
import logoUrl from './assets/logo.svg';
import { useRouterLink } from './composables/router';

const userProfileLink = useRouterLink('UserProfile');
const fileManagerLink = useRouterLink('FileManagerIndex');

const router = useRouter();
const routeInfo = useRoute();

const routeName = computed(() => {
  // can be string or symbol but this code always has worked
  // overriding the type to string
  return routeInfo.name as string;
});

// base setup
const userStore = useUserStore();
const baseURL = import.meta.env.VITE_API_BASE_URL;

// Setup for manually managing the settings menu open/close state
// We have to set it up this way in order to facilitate a third party widget
// being opened alongside our settings menu
const settingsMenuOpen = ref(false);
const menuRef = ref(null);

setupInterceptor();

onBeforeMount(async () => {
  await userStore.fetchUser();
  userStore.updateCurrentTeam(Number.parseInt(routeInfo?.params?.orgId as string) || 0);
});

watchEffect(() => {
  const orgId = routeInfo?.params?.orgId;
  if (orgId && userStore.availableTeams.length > 0 && !userStore.availableTeams.map(team => String(team.id)).includes(String(orgId))) {
    userStore.updateCurrentTeam(userStore.currentUser?.organization_team.id || 0);
    router.replace({
      name: 'Forbidden',
      params: {
        orgId: userStore.currentUser?.organization_team.id,
      },
    });
  }
});

onMounted(() => {
  // Initialize canny's changelog button
  // This handles the click event, and the notification badge
  window.Canny('initChangelog', {
    appID: '65947205893793303f300b7c',
    position: 'left',
    align: 'top',
    theme: 'light',
  });
});

// Detect clicks outside of the settings menu as we're now manually
// managing it's open/close state
onClickOutside(menuRef, () => closeSettingsMenu());

// On click of the user's name, we toggle the setting menu open or close
const onSettingsMenuClicked = () => {
  if (settingsMenuOpen.value) {
    closeSettingsMenu();
    return;
  }
  openSettingsMenu();
};

const openSettingsMenu = () => {
  settingsMenuOpen.value = true;
};

const closeSettingsMenu = () => {
  settingsMenuOpen.value = false;
};

// This key press listener is on the settings menu items. This only fires when the menu items
// container is in focus. NOTE: When the canny widget is open, this will not fire as the canny iframe
// has focus.
const onEscape = () => {
  closeSettingsMenu();
};

// alert setup
const alertStore = useAlertStore();
const updateShow = () => alertStore.closeAlert();

// user/team setup
const currentUser = computed(() => userStore.currentUser);
const currentTeamId = computed(() => userStore?.currentTeam?.id || 0);

// dropdown setup
const viewTeamDropdown = computed(() => {
  return userStore.availableTeams?.length > 1;
});
const selectedTeam = computed(() => userStore?.currentTeam);
const updateSelectedTeam = (team: { id: number }) => {
  const teamId = team.id;
  userStore.updateCurrentTeam(teamId);
  router.replace({ params: { orgId: teamId } });
};

// options for team dropdown
const availableTeams = computed(() => userStore.availableTeams);

// menu navigation setup
const navigation = computed(() => [
  { name: 'Home', href: `${baseURL}/teams/${currentTeamId.value}/stats/campaigns`, current: false },
]);

const userNavigation = computed(() => [
  { name: 'Users', href: `${baseURL}/organizations/users` },
  { name: 'Teams', href: `${baseURL}/teams` },
  { name: 'Resources', href: 'https://www.contactmonkey.com/resources' },
]);

async function logout() {
  closeSettingsMenu();
  await cmApi.delete('/users/log_out').then(() => {
    document.location.href = `${baseURL}/users/sign_in?layout=desktop`;
  });
}

const smsCampaignsIndexLink = useRouterLink('SmsCampaignsIndex');
const emailsIndexLink = useRouterLink('EmailsIndex');
const smsCampaignNewLink = useRouterLink('SmsCampaignsNew');
const switchToValues = computed(() => {
  if (routeName.value?.startsWith('Sms')) {
    return { name: 'Switch to Mail', route: emailsIndexLink as RouteLocationRaw };
  }
  else if (!routeName.value?.startsWith('Sms') && userStore.smsTrialUser) {
    return { name: 'Switch to SMS Trial', route: smsCampaignNewLink as RouteLocationRaw };
  }
  else {
    return { name: 'Switch to SMS', route: smsCampaignsIndexLink as RouteLocationRaw };
  }
});
</script>

<template>
  <div class="min-h-full">
    <div id="banner" /> <!-- This allows Vue-teleport to push items to the very top of the page. -->
    <CAlert
      :show="alertStore.showAlert"
      :alert-type="alertStore.alertType"
      :message="alertStore.message"
      :description="alertStore.description"
      @close-alert="updateShow"
    />

    <div>
      <Disclosure
        v-slot="{ open }"
        as="nav"
        class="border-b border-gray-200 bg-white"
      >
        <div class="mx-auto max-w-7xl px-4 lg:px-8 sm:px-6">
          <div class="h-16 flex justify-between">
            <div class="flex">
              <div class="flex flex-shrink-0 items-center">
                <img
                  class="logo-img block h-14"
                  :src="logoUrl"
                  alt="Workflow"
                >
              </div>
              <div
                v-if="userStore.isUserDual && !routeName?.startsWith('Sms')"
                class="sm:ml-6 sm:flex sm:-my-px sm:space-x-8"
              >
                <NavBar />
              </div>
              <div
                v-else-if="userStore.isUserDual && routeName?.startsWith('Sms')"
                class="sm:ml-6 sm:flex sm:-my-px sm:space-x-8"
              >
                <SmsNavBar />
              </div>
              <div
                v-else-if="userStore.isUserMailOnly"
                class="sm:ml-6 sm:flex sm:-my-px sm:space-x-8"
              >
                <NavBar />
              </div>
              <div
                v-else-if="userStore.isUserSmsOnly"
                class="sm:ml-6 sm:flex sm:-my-px sm:space-x-8"
              >
                <SmsNavBar />
              </div>
              <div
                v-else
                class="sm:ml-6 sm:flex sm:-my-px sm:space-x-8"
              >
                <NavBar />
              </div>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:items-center">
              <!-- Organization Team Dropdown -->
              <div
                v-if="viewTeamDropdown && selectedTeam"
                class="my-4 w-60 items-center sm:flex"
              >
                <div class="w-inherit">
                  <CSelect
                    v-model="selectedTeam"
                    :options="availableTeams"
                    key-property="id"
                    @update:model-value="updateSelectedTeam"
                  />
                </div>
              </div>
              <!-- Profile dropdown -->
              <Menu
                ref="menuRef"
                as="div"
                class="relative ml-3"
              >
                <div>
                  <MenuButton
                    class="group max-w-xs flex items-center rounded-full bg-white text-sm"
                    @click="onSettingsMenuClicked"
                  >
                    <span id="profile-pic" class="pointer-events-none h-9 w-9 inline-flex items-center justify-center rounded-full bg-primary-100 ring-1 ring-primary-200/20 ring-inset transition group-hover:ring-primary-300" data-canny-changelog>
                      <span class="text-primary-300 font-bold leading-none">{{ currentUser?.firstname?.charAt(0) }}{{ currentUser?.lastname?.charAt(0) }}</span>
                    </span>
                    <div class="ml-3 flex flex-col items-start">
                      <span class="text-sm text-gray-600 font-bold transition group-hover:text-gray-900">{{ `${currentUser?.firstname || ''} ${currentUser?.lastname || ''}` }}</span>
                      <span class="text-xs text-gray-500 font-medium transition group-hover:text-gray-700">{{ currentUser?.organization_team?.name || '' }}</span>
                    </div>
                  </MenuButton>
                </div>

                <transition
                  enter-active-class="transition ease-out duration-200"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    v-show="settingsMenuOpen"
                    id="settings-menu-items"
                    class="absolute z-20 mt-2 w-64 origin-top-right rounded-md bg-white p-1 shadow-lg ring-1 ring-slate-700/10 -right-2 focus:outline-none"
                    static
                    @keydown.escape="onEscape"
                  >
                    <MenuItem v-slot="{ active }">
                      <router-link
                        class="group flex rounded px-2 py-2 text-sm text-slate-700 transition hover:text-primary-600" :class="[active ? 'bg-primary-50 text-primary-700 ' : '']"
                        :to="userProfileLink"
                        aria-current="page"
                      >
                        <div
                          class="w-full flex gap-2 align-middle"
                          @click="closeSettingsMenu"
                        >
                          <span class="i-heroicons-solid-cog inline-block h-5 w-5 text-slate-400 transition-1000 group-hover:text-primary-400" />
                          <span>Settings</span>
                        </div>
                      </router-link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        class="group flex rounded px-2 py-2 text-sm text-slate-700 transition hover:text-primary-600" :class="[active ? 'bg-primary-50 text-primary-700 ' : '']"
                        :to="fileManagerLink"
                        aria-current="page"
                      >
                        <div
                          class="w-full flex gap-2 align-middle"
                          @click="closeSettingsMenu"
                        >
                          <span class="i-fa6-solid-file-image inline-block h-5 w-5 text-slate-400 transition-1000 group-hover:text-primary-400" />
                          <span>File Manager</span>
                        </div>
                      </router-link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a
                        href="https://www.contactmonkey.com/resources"
                        class="group flex gap-2 rounded px-2 py-2 align-middle text-sm text-slate-700 transition hover:text-primary-600" :class="[active ? 'bg-primary-50 text-primary-700 ' : '']"
                        @click="closeSettingsMenu"
                      >
                        <span class="i-heroicons:academic-cap-20-solid inline-block h-5 w-5 text-slate-400 group-hover:text-primary-400" /> Resources
                      </a>
                    </MenuItem>
                    <hr
                      class="my-1 border-t border-slate-200"
                    >
                    <MenuItem v-slot="{ active }">
                      <a
                        data-canny-link
                        href="https://contactmonkey.canny.io/feature-requests"
                        target="_blank"
                        class="group flex rounded px-2 py-2 text-sm text-slate-700 transition hover:text-primary-600" :class="[active ? 'bg-primary-50 text-primary-700 ' : '']"
                        @click="closeSettingsMenu"
                      >
                        <div
                          class="w-full flex gap-2 align-middle"
                        >
                          <span class="i-fluent:person-feedback-24-filled inline-block h-5 w-5 text-slate-400 transition-1000 group-hover:text-primary-400" />
                          <span>Product Feedback</span>
                        </div>
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" data-canny-changelog>
                      <div
                        class="group flex cursor-pointer rounded px-2 py-2 text-sm text-slate-700 transition hover:text-primary-600" :class="[active ? 'bg-primary-50 text-primary-700 ' : '']"
                        aria-current="page"
                      >
                        <div
                          class="w-full flex gap-2 align-middle"
                        >
                          <span class="i-heroicons-solid:bell inline-block h-5 w-5 text-slate-400 transition-1000 group-hover:text-primary-400" />
                          <span>Product Updates</span>
                        </div>
                      </div>
                    </MenuItem>
                    <hr
                      class="my-1 border-t border-slate-200"
                    >
                    <MenuItem
                      v-if="userStore.dualUser || userStore.smsTrialUser"
                      v-slot="{ active }"
                    >
                      <router-link
                        v-if="routeInfo.params.orgId || currentTeamId || currentTeamId === 0"
                        :to="switchToValues.route"
                        class="group flex items-center justify-start rounded px-2 py-2 text-sm text-slate-700 transition hover:text-primary-600" :class="[active ? 'bg-primary-50 text-primary-700 ' : '']"
                      >
                        <div
                          class="w-full flex gap-2 align-middle"
                          @click="closeSettingsMenu"
                        >
                          <i-heroicons-solid-refresh class="inline-block h-5 w-5 text-slate-400 group-hover:text-primary-400" />
                          <span>{{ switchToValues.name }}</span>

                          <span
                            v-if="!routeName?.startsWith('Sms') && userStore.smsTrialUser"
                            class="ml-7 inline-flex items-center rounded-full bg-blue-50 px-3 py-1 text-xs text-blue-700 font-medium ring-1 ring-blue-700/10 ring-inset"
                          >New</span>
                        </div>
                      </router-link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <span
                        class="group mr-0.5 flex cursor-pointer gap-2 rounded px-2 py-2 align-middle text-sm text-slate-700 transition hover:text-primary-600" :class="[active ? 'bg-primary-50 text-primary-700 ' : '']"
                        @click="logout"
                      >
                        <i-ion-exit class="ml-0.5 inline-block h-5 w-5 text-slate-400 group-hover:text-primary-400" />
                        Log Out
                      </span>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
            <div class="flex items-center -mr-2 sm:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                <span class="sr-only">Open main menu</span>
                <i-heroicons-outline-menu
                  v-if="!open"
                  class="block h-6 w-6"
                  aria-hidden="true"
                />
                <i-heroicons-outline-x
                  v-else
                  class="block h-6 w-6"
                  aria-hidden="true"
                />
              </DisclosureButton>
            </div>
          </div>
        </div>

        <DisclosurePanel class="sm:hidden">
          <div class="pb-3 pt-2 space-y-1">
            <DisclosureButton
              v-for="item in navigation"
              :key="item.name"
              as="a"
              :href="item.href"
              class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium" :class="[item.current ? 'bg-indigo-50 border-primary-500 text-indigo-700' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800']"
              :aria-current="item.current ? 'page' : undefined"
            >
              {{ item.name }}
            </DisclosureButton>
          </div>
          <div class="border-t border-gray-200 pb-3 pt-4">
            <div class="flex items-center px-4">
              <div class="flex-shrink-0">
                <span class="h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-500">
                  <span class="text-white font-medium leading-none">{{ currentUser?.firstname?.charAt(0) }}</span>
                </span>
              </div>
              <div v-if="currentUser" class="ml-3">
                <div class="text-base text-gray-800 font-medium">
                  {{ currentUser.firstname }}
                </div>
                <div class="text-sm text-gray-500 font-medium">
                  {{ currentUser.lastname }}
                </div>
              </div>
            </div>
            <div class="mt-3 space-y-1">
              <router-link
                :to="userProfileLink"
                class="block px-4 py-2 text-base text-gray-500 font-medium hover:bg-gray-100 hover:text-gray-800"
                aria-current="page"
              >
                Settings
              </router-link>

              <DisclosureButton
                v-for="item in userNavigation"
                :key="item.name"
                as="a"
                :href="item.href"
                class="block px-4 py-2 text-base text-gray-500 font-medium hover:bg-gray-100 hover:text-gray-800"
              >
                {{ item.name }}
              </DisclosureButton>
              <DisclosureButton
                class="block px-4 py-2 text-base text-gray-500 font-medium hover:bg-gray-100 hover:text-gray-800"
                @click="logout"
              >
                Log Out
              </DisclosureButton>
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>

      <div class="py-6">
        <main>
          <div class="mx-auto max-w-7xl lg:px-8 sm:px-6">
            <!-- do not change this code, if changed it will create a cascading set of bugs throughout the application -->
            <router-view v-slot="{ Component }">
              <template v-if="Component">
                <transition mode="out-in">
                  <keep-alive>
                    <suspense>
                      <component :is="Component" />
                      <template #fallback>
                        <div>
                          Loading...
                        </div>
                      </template>
                    </suspense>
                  </keep-alive>
                </transition>
              </template>
            </router-view>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<style>
.logo-img {
  width: 44.33px;
}

/* Had to override some styling on the Canny changelog badge
 in order to have it center aligned with the menu item */
.Canny_BadgeContainer .Canny_Badge {
  top: 0;
  right: 8px;
  transform: translate(0, 100%)
}

#profile-pic .Canny_BadgeContainer .Canny_Badge {
  right: 0;
  transform: translate(10%, -10%);
}
</style>
